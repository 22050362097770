import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import SEO from '../components/seo'
import Pills from '../components/pills'
import Embed from '../components/embed'
import Toolbar from '../components/toolbar'
import { formatPostDate, formatReadingTime } from '../utils/dates'

import './blog-post.css'
import Footer from '../components/footer'
import Share from '../components/Share'

export default function PageTemplate({ data: { mdx, site }, pageContext }) {
  const { previous, next } = pageContext
  const publicUrl = `${site.siteMetadata.siteUrl}${mdx.fields.slug}`

  let cover = undefined;
  if (mdx.frontmatter.cover && mdx.frontmatter.cover.childImageSharp && mdx.frontmatter.cover.childImageSharp.fixed) {
    cover = `${site.siteMetadata.siteUrl}${mdx.frontmatter.cover.childImageSharp.fixed.src}`
  }

  return (
    <div>
      <Toolbar />
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        canonicalLink={mdx.frontmatter.canonical_link}
        keywords={mdx.frontmatter.categories || []}
        cover={cover}
        meta={[
          {
            name: 'twitter:label1',
            content: 'Reading time',
          },
          {
            name: 'twitter:data1',
            content: `${mdx.timeToRead} min de lecture`,
          },
        ]}
      />
      <section className="center blog">
        <article className="container small">
          <header>
            <h1>{mdx.frontmatter.title}</h1>

            <p>{mdx.frontmatter.author}</p>

            <p>
              Publié le {formatPostDate(mdx.frontmatter.date)} - {formatReadingTime(mdx.timeToRead)}
            </p>

            <Share
              socialConfig={{
                config: {
                  url: publicUrl,
                  title: mdx.frontmatter.title,
                },
              }}
              tags={mdx.frontmatter.categories}
            />
          </header>

          <MDXRenderer scope={{ Embed }}>{mdx.body}</MDXRenderer>

          <Pills items={mdx.frontmatter.categories} />

          <br />
          <br />

        </article>
      </section>
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
    query BlogPostQuery($id: String) {
        site {
            siteMetadata {
                siteUrl
                githubUrl
            }
        }
        mdx(id: { eq: $id }) {
            fields {
                slug
            }
            timeToRead
            frontmatter {
                title
                description
                categories
                author
                cover {
                  childImageSharp {
                    fixed(width: 600, height: 315) {
                      src
                    }
                  }
                }
                date(formatString: "MMMM DD, YYYY")
                canonical_link
            }
            body
        }
    }
`
